import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../services";
import { ThreeDots } from 'react-loader-spinner';

export const CertificateForm = ({ certificate, voucher, setIsOpen, city }) => {
  const navigate = useNavigate();
  const [isSend, setIsSend] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the CardElement.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const stripeStyle = {
    style: {
      base: {
        color: "#d1d1d1",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#d1d1d1"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSend(true)
    const card = elements.getElement(CardElement);

    // add these lines
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });

    ApiService.saveStripeInfo({
      name,
      email,
      payment_method_id: paymentMethod.id,
      phone,
      voucher: voucher.id,
      certificate: certificate.id,
      price: String(voucher.price) + '00',
      city
    })
      .then((response) => {
        if(response.data.message === "Success") {
          setIsSend(false)
          navigate(`/${city}/thanks`);
        }
        // setIsOpen(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setError(error.response.data?.user_message)
        setIsSend(false)
      });
  };
  return (
    <div className="stripe">
      <form onSubmit={handleSubmit} className="stripe-form">
        <div className="form-row">
          <label htmlFor="name"></label>
          <input
            className="form-input"
            id="name"
            name="name"
            type="name"
            placeholder="Buyer name"
            required
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="email"></label>
          <input
            className="form-input"
            id="email"
            name="name"
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="phone"></label>
          <input
            className="form-input"
            id="phone"
            name="name"
            type="phone"
            placeholder="Phone number"
            required
            value={phone}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="phone"></label>
          <input
            className="form-input"
            id="certificate"
            name="name"
            type="text"
            value={certificate.name}
            disabled
          />
        </div>
        <div className="form-row">
          <label htmlFor="phone"></label>
          <input
            className="form-input"
            id="voucher"
            name="name"
            type="text"
            value={`${voucher.name} /${voucher.price}$ /${voucher.people} people`}
            disabled
          />
        </div>
        <div className="form-row stripe-row">
          <label htmlFor="card-element">Credit or debit card</label>
          <CardElement id="card-element" options={stripeStyle} onChange={handleChange} />
          <div className="card-errors" role="alert">
            {error}
          </div>
        </div>
        <button disabled={isSend} type="submit" className="btn submit-btn" style={{marginTop: 30}}>
          {
            isSend ? 
            <ThreeDots height="25" width="40" radius="1" color="white" ariaLabel="loading" visible={true} /> :
            "Submit Payment" 
          }
        </button>
      </form>
    </div>
  );
};
