import React from "react";
import { Link } from "react-router-dom";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import { ReviewReveal } from "../components/Reviews";
import Fade from "react-reveal/Fade";
import Xarrow from "react-xarrows";
import { MetaDecorator } from "../components";


import { ExperienceStack } from '../components/ExperienceStack';
import parallaxBack from "../assets/img/parallax/background_base.webp";
import parallaxZ1 from "../assets/img/parallax/robots.webp";
import parallaxZ2 from "../assets/img/parallax/zombie_right.webp";
import parallaxZ3 from "../assets/img/parallax/zombie_left.webp";
import tykva1 from "../assets/img/halloween/s.webp"
import tykva2 from "../assets/img/halloween/t1.webp"
import tykva3 from "../assets/img/halloween/t2.webp"
import infoVideo from "../assets/video/Render_scene-.mp4";

export function Main({ city }) {

  return (
    <>
      <MetaDecorator
        description={`Welcome to the future of entertainment! Virtual reality, team building, corporate event, laser tag, arcade at Anvio VR PARK ${city.toUpperCase()}`}
        title={`Anvio VR Park of ${city.toUpperCase()}`}
        imageUrl={"/anvio.jpg"}
        imageAlt={"Anvio Park Home"}
        pageUrl={`https://anviopark.com/${city}`}
      />
      <div className="main">
        <div className="container" style={{zIndex: 1}}>
          <div className="main__inner">
            <div className="main-title">Welcome to the future of entertainment</div>
            <div className="description">Full immersion vr experience at {city.toUpperCase()}</div>
            <div className="actions">
              <Link to={`/${city}/book`} className="btn btn-primary">
                Book an adventure
              </Link>
              <Link to={`/${city}/certificates`} className="btn btn-secondary">
                Purchase gift certificate
              </Link>
              {/* {city === "battleground" ?  
                <Link to={`/${city}/halloween`} className="btn btn-halloween">
                  Halloween Party
                </Link> : null
              } */}
            </div>
          </div>
        </div>
        
        <MouseParallaxContainer
          className="paralax_background"
          useWindowMouseEvents
        >
          <MouseParallaxChild
            className="paralax_child1"
            factorX={0.02}
            factorY={0.03}
          >
            <img src={parallaxZ1} alt="b" />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="paralax_child3"
            factorX={0.03}
            factorY={0.04}
          >
            <img src={parallaxZ3} alt="d" />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="paralax_child2"
            factorX={0.06}
            factorY={0.07}
          >
            <img src={parallaxZ2} alt="c" />
          </MouseParallaxChild>
        </MouseParallaxContainer>
        {/* {city === "battleground" ?  
          <div style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0}}>
            <MouseParallaxContainer
            className="paralax_background"
            useWindowMouseEvents
            >
            <MouseParallaxChild
              className="hallo_paralax"
              factorX={0.01}
              factorY={0.02}
            >
              <img src={tykva2} alt="d" style={{position: "absolute", bottom: 20, minWidth: 1200, right: 0, maxWidth: "100vw"}}/>
            </MouseParallaxChild>
            <MouseParallaxChild
              className="hallo_paralax"
              factorX={0.02}
              factorY={0.03}
            >
              <img src={tykva3} alt="b" style={{position: "absolute", bottom: 20, minWidth: 1200, right: 0, maxWidth: "100vw"}}/>
            </MouseParallaxChild>
            <MouseParallaxChild
              className="hallo_paralax"
              factorX={0.06}
              factorY={0.07}
            >
              <img src={tykva1} alt="c" style={{position: "absolute", bottom: 20, minWidth: 1200, right: 0, maxWidth: "100vw"}}/>
            </MouseParallaxChild>
          </MouseParallaxContainer>
          </div> : null 
        } */}
      </div>

      <ExperienceStack stackTitle={"Trending VR adventures"} city={city}/>

      <ReviewReveal />
      <div className="info">
        <div className="info__cover">
          <div className="info__inner container">
            <ul>
            <Fade top distance="50px"><div className="secondary-title" style={{textAlign: "center"}}>IT IS NEVER TOO LATE TO SAVE THE WORLD:</div></Fade>
              <div className="infographics">
                <Fade left distance="50px">
                  <div className="infographics__left">
                    <div id="a" className="btn btn-primary bubbles">
                      Put on the headset
                    </div>
                  </div>
                </Fade>
                <Fade right distance="50px" >
                  <div className="infographics__right">
                    <div id="b" className="btn btn-primary bubbles">
                      Grab your gun for a monsters shooting
                    </div>
                  </div>
                </Fade>
                <Fade delay={100}><Xarrow start = "a" end="b" strokeWidth={3} startAnchor={"bottom"} endAnchor={"top"} showHead={false} color="#fff"/></Fade>
                <Fade left distance="50px">
                  <div className="infographics__left">
                    <div id="c" className="btn btn-secondary bubbles">
                      Interact with objects and coplayers
                    </div>
                  </div>
                </Fade>
                <Fade delay={100}><Xarrow start = "b" end="c" strokeWidth={3} startAnchor={"bottom"} endAnchor={"top"} showHead={false} color="#fff"/></Fade>
                <Fade right distance="50px">
                  <div className="infographics__right">
                    <div id="d" className="btn btn-secondary bubbles">
                      Feel the touches and shots
                    </div>
                  </div>
                </Fade>
                <Fade delay={100}><Xarrow start = "c" end="d" strokeWidth={3} startAnchor={"bottom"} endAnchor={"top"} showHead={false} color="#fff"/></Fade>
                <Fade left distance="50px">
                  <div className="infographics__left">
                    <div id="e" className="btn btn-primary bubbles">
                      Complete the mission and pose for your social media
                    </div>
                  </div>
                </Fade>
                <Fade delay={100}><Xarrow start = "d" end="e" strokeWidth={3} startAnchor={"bottom"} endAnchor={"top"} showHead={false} color="#fff"/></Fade>
              </div>
            </ul>
          </div>
          <div className="vid_container">
            <div className="background-video" 
              dangerouslySetInnerHTML={{
                  __html: `
                  <video
                    class='main-video'
                    loop
                    muted
                    autoplay
                    playsinline
                    preload="auto"
                  >
                  <source src="${infoVideo}" type="video/mp4" />
                  </video>`
                }}>
            </div>
          </div>
        </div>
      </div>

      <ExperienceStack stackTitle={"Book your VR adventure"} city={city}/>
    </>
  );
}
