import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ExperienceStack } from '../../components/ExperienceStack';
import Fade from "react-reveal/Fade";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import {MetaDecorator} from "../../components";

import czImg1 from "../../assets/img/experiences/survivors/clown.webp";
import cz1 from "../../assets/img/experiences/survivors/surv1.webp";
import cz3 from "../../assets/img/experiences/survivors/surv2.webp";
import cz4 from "../../assets/img/experiences/survivors/surv4.webp";
import z1 from "../../assets/img/experiences/survivors/z1.webp";
import z2 from "../../assets/img/experiences/survivors/z2.webp";
import z3 from "../../assets/img/experiences/survivors/z3.webp";
import mainBg from "../../assets/img/experiences/survivors/surv_main.webp"
import secondBg from "../../assets/img/experiences/survivors/surv3.webp"
import genre_dec from "../../assets/img/decoration/genre.png"
import people_dec from "../../assets/img/decoration/people.png"
import time_dec from "../../assets/img/decoration/time.png"

export function CityZSurvivors({ city }) {
  const [isTrailerOpened, setIsTrailerOpened] = useState(false);
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  
  return (
    <div className="experiences">
      <MetaDecorator
        description=
            {
            `CityZ.Survivors - Part two of a legendary City Z. Are you still alive?`
            }
        title={`Anvio VR Park - CityZ.Survivors VR | ${city.toUpperCase()}`}
        imageUrl={mainBg}
        imageAlt={"Anvio Park CityZ.Survivors"}
        pageUrl={`https://anviopark.com/${city}/experiences/CITYZ.Survivors`}
      />
      <div className="main_background">
        <img src={mainBg} alt="" style={{width: screenWidth}}/>
        <MouseParallaxContainer
        className="paralax_background"
        useWindowMouseEvents
        >
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.01}
          factorY={0.02}
        >
          <img src={z2} alt="d" />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.04}
          factorY={0.05}
        >
          <img src={z1} alt="b" />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.07}
          factorY={0.08}
        >
          <img src={z3} alt="c" />
        </MouseParallaxChild>
      </MouseParallaxContainer>
      </div>
      <div style={{position: "absolute", width: "100%", height: "100%"}}>
        <Fade duration={500} when={isTrailerOpened} collapse >
          <div className="trailer" onClick={() => setIsTrailerOpened(false)}>
            <iframe 
              style={{ width: "80%", height: "auto", aspectRatio: "16/9" }}
              width="1280" height="720" 
              src="https://www.youtube.com/embed/0cg2GfZq4YI?autoplay=0&controls=1&modestbranding=0&playsinline=1"
              showinfo="0" 
              title="YouTube video player" frameBorder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope;" 
              allowFullScreen></iframe>
          </div>
        </Fade>
      </div>
      <div className="main">
        <div className="container">
          <div className="main__inner">
            <Fade left cascade duration={500} distance="50px">
              <div className="left">
                <div className="main-title">City Z. Survivors</div>
                <div className="description"><img src={genre_dec} alt="" />Genre: Horror/Shooter</div>
                <div className="description"><img src={people_dec} alt="" />People: 1-6 people</div>
                <div className="description"><img src={time_dec} alt="" />Session time: 30 mins</div>
                <div className="actions">
                  <Link to={`/${city}/book`} className="btn btn-primary">
                    Book an adventure
                  </Link>
                  <div className="btn btn-secondary" onClick={() => setIsTrailerOpened(true)}>
                    Watch trailer
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="experience" style={{height: screenHeight}}>
        <div className="secondary_background">
          <img src={secondBg} alt="" style={{width: screenWidth}}/>
        </div>
        <div className="container">
            <div className="experience__inner">
              <Fade left distance="50px">
                <div className="title">
                  «Part two of a legendary City Z. Are you still alive?»
                </div>
              </Fade>
              <div className="description">
                <Fade top >
                  <p>Good. The first City Z mission has been completed,</p>
                  <p>
                    there is no hope of salvation - your group will have to find their way out of the hollow buildings of the City's towers on their own.
                  </p>
                  <p>
                    Attacking zombies have already found out about you. 
                  </p>
                  <p>
                    Will you become a hero or a dinner? 
                  </p>
                </Fade>
              </div>
            </div>
        </div>
      </div>
      
      <div className="intro">
        <div className="container">
          <div className="intro__inner">
            <Fade left cascade>
              <div className="intro__left" style={{left: -50}}>
                <img
                  src={czImg1}
                  className="img-responsive"
                  alt=""
                />
              </div>
            </Fade>
            <Fade right cascade>
              <div className="grid">
                <img
                  src={cz1}
                  className="grid__element"
                  alt=""
                />
                <img
                  src={cz3}
                  className="grid__element"
                  alt=""
                />
                <img
                  src={cz4}
                  className="grid__element"
                  alt=""
                />
                
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="booking">
        <div className="container">
          <Fade top cascade>
            <div className="booking__inner">
              <div className="modal__title">It's time to save the living</div>
              <div>
                <Link to={`/${city}/book`} className="btn btn-primary">
                  Book an adventure
                </Link>
              </div>
            </div>
          </Fade>
        </div>
        <div className="booking__cover"></div>
      </div>
      <ExperienceStack stackTitle={"Trending VR adventures"} city={city}/>
    </div>
  );
}
