import React from "react";
import { useParams } from "react-router-dom";
import { CityZ, LostSanctuary, StationZarya, DragonVR, CityZSurvivors, CityZAntidote, Versus } from "../experiences"

export function Experiences({ city }) {
  const {id} = useParams();
  
  switch(id) {
    case "CITYZ": 
      return (
        <CityZ city={city} />
      );
    
    case "LOSTSANCTUARY": 
      return (
        <LostSanctuary city={city} />
      );

    case "STATIONZARYA": 
      return (
        <StationZarya city={city} />
      );

    case "CITYZ.Survivors": 
    return (
      <CityZSurvivors city={city} />
    );

    case "CITYZ.Antidote": 
      return (
        <CityZAntidote city={city} />
      );

    case "DragonVR": 
    return (
      <DragonVR city={city} />
    );

    case "Versus": 
    return (
      <Versus city={city} />
    );

    default:
  }
}
