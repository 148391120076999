import React from "react";

export function Package({ pack, selected, setSelected, open, setOpen }) {
  return (
    <div className={`package`}>
      <div className="package__inner">
        <div>
          <div className="package__inner--hours">{pack.hour > 1 ? pack.hour === 8 ? "WHOLE DAY" : pack.hour + " HOURS" : pack.hour + " HOUR"}</div>
          <div className="package__inner--small">up to</div>
          <div className="package__inner--people">{pack.people} people</div>
          <div className="package__inner--description" dangerouslySetInnerHTML={{ __html: pack.description }}></div>
        </div>
        <div className="package__additionaly">
          <div className="package__additionaly--title">ADDITIONALLY:</div>
          <div className="package__additionaly--ul">
            <div className="package__additionaly--li"><b>{pack.byob ? " BYOB & " : null}</b>Cleaning fee</div>
            <div className="package__additionaly--li free">{pack.byob > 0 ? "$" + pack.byob : "FREE"}</div>
          </div>
          {
            
            pack.additionalGuest > 0 ? 
              <div className="package__additionaly--ul">
                <div className="package__additionaly--li">Additional guest</div>
                <div className="package__additionaly--li price">${pack.additionalGuest}</div>
              </div> : null
          }
          {
            pack.catering ? 
            <div className="package__additionaly--ul">
              <div className="package__additionaly--li">Catering</div>
              <div className="package__additionaly--li availablev">Available</div>
            </div> : null
          }
          {
            pack.photographer ? 
            <div className="package__additionaly--ul">
              <div className="package__additionaly--li">Photographer</div>
              <div className="package__additionaly--li price">Upon request</div>
            </div> : null
          }
          <div className="package__additionaly--ul">
            <div className="package__additionaly--li">Additional hour</div>
            <div className="package__additionaly--li price">{pack.additionalHour > 0 ? "$" + (pack.additionalHour).toLocaleString() : "N/A"}</div>
          </div>
        </div>
        <div className="package__price">${(pack.price).toLocaleString()}</div>
        <div className="btn submit-btn" onClick={() => {setSelected(pack); setOpen(true)}} style={{width: "50%", alignSelf: "center"}}>
          Order Now
        </div>
      </div>
    </div>
  );
}
