import React, { useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';

import ApiService from "../services";

export function MainForm({selected, formTitle, city}) {
  const [isSend, setIsSend] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setIsSend(true)
    console.log('citycity', city);
    ApiService.saveApplications({...data, selected: selected, city: city})
      .then((response) => {
        console.log(response.data);
        setIsSend(false)
        navigate(`/${city}/thanks`);
        reset()
      })
      .catch((error) => {
        console.log(error);
        setIsSend(false)
      });
  };


  return (
    <div className="main__form">
      <div className="main__form--title">{formTitle}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="main__form--group small">
          <div className="main__form--block">
            {errors.name && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="text"
              className="main__form--input"
              placeholder="Name"
              {...register("name", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.company && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="text"
              className="main__form--input"
              placeholder="Company"
              {...register("company", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.email && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="email"
              className="main__form--input"
              placeholder="Email"
              {...register("email", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.phone && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="text"
              className="main__form--input"
              placeholder="Phone"
              {...register("phone", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.guests && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="number"
              className="main__form--input"
              placeholder="# of guests"
              value={selected ? selected.people : null}
              {...register("guests", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.dateOfEvent && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="date"
              className="main__form--input"
              placeholder="Date of Event"
              {...register("dateOfEvent", { required: true })}
            />
          </div>
        </div>

        <div className="main__form--group">
          <div className="main__form--block">
            {errors.state && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="text"
              className="main__form--input"
              placeholder="State"
              {...register("state", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.location && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="text"
              className="main__form--input"
              placeholder="Choose location"
              {...register("location", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.hearUs && (
              <span className="main__form--label">This field is required</span>
            )}
            <input
              type="text"
              className="main__form--input"
              placeholder="How did youhear about us?"
              {...register("hearUs", { required: true })}
            />
          </div>
          <div className="main__form--block">
            {errors.yourEvent && (
              <span className="main__form--label">This field is required</span>
            )}
            <textarea
              rows="4"
              type="text"
              className="main__form--input"
              placeholder="Tell us about your event..."
              {...register("yourEvent", { required: true })}
            />
          </div>
          {selected && selected.price ? <div className="main__form--block">
            <input
              type="text"
              className="main__form--input"
              disabled
              value={`/${selected.price}$ /${selected.people} people`}
              {...register("package")}
            />
          </div> : null}
          <div className="main__form--actions">
            {
              isSend ? 
              <ThreeDots height="25" width="40" radius="1" color="white" ariaLabel="loading" visible={true} 
              wrapperClass={"btn btn-primary"} /> :
              <input
                type="submit"
                className="btn main__form--btn"
                placeholder="Submit"
                disabled={isSend}
              /> 
            }
          </div>
        </div>
      </form>
    </div>
  );
}
