import React, {useState, useEffect, useRef} from 'react'
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade'
import ApiService from "../services";

import arrowRight from "../assets/img/right-arrow.png";
import arrowLeft from "../assets/img/left-arrow.png";

export function ExperienceStack({stackTitle, city}) {
    const [games, setGames] = useState([]);
    
    function scrollCardsRight(event) {
        if(event.target.parentNode)
            event.target.parentNode.scrollLeft += 200;
    }

    function scrollCardsLeft(event) {
        if(event.target.parentNode)
            event.target.parentNode.scrollLeft -= 200;
    }

    async function playVid(event) {
        if(event.target.className === "videoElement") {
            event.target.style.opacity = 1;
            try {
               await event.target.load();
               await event.target.play();
            } catch(err) {

            }
        }
    }

    async function stopVid(event) {
        if(event.target.className === "videoElement") {
            event.target.style.opacity = 0;
            try {
                await event.target.pause();
             } catch(err) {
 
             }
        } 
    }
    useEffect(() => {
        ApiService.getGames()
            .then(response => response.data)
            .then(data => {
            setGames(data)
        })
        
    }, [])

    return (
        <div className="section2">
            <div className="container">
                <div className="section2__inner">
                    <div className="section2__head">
                    <Fade top>
                        <div className="title">{stackTitle}</div>
                    </Fade>
                    </div>
                    <Fade right cascade>
                        {
                            city !== "hollywood" && city !== "battleground-sf"
                            ?   <div className="cards">
                                    {
                                    games.length > 0 && games.map((game, index) => <Link to={`/${city}/experiences/${(game.name).replace( /\s/g, '')}`} className="card" key={index} style={{ 
                                        backgroundImage: `url("${game.image_512}")` 
                                    }} onMouseOver={playVid} onMouseOut={stopVid}>
                                        <div className='card__video'>
                                            <video className='videoElement' muted>
                                                <source src={game.video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>

                                    <div className="card__title">
                                    {game.name}
                                    </div>
                                    </Link>)
                                    }
                                    <img className="arrow-right" src={arrowRight} alt="" onClick={scrollCardsRight}/>
                                    <img className="arrow-left" src={arrowLeft} alt="" onClick={scrollCardsLeft} />
                                </div> 
                            :   <div className="cards">
                                    {
                                        games.length > 0 && games.map((game, index) => game.name !== "Versus" 
                                            ?   <Link to={`/${city}/experiences/${(game.name).replace( /\s/g, '')}`} className="card" key={index} style={{ backgroundImage: `url("${game.image_512}")` }} onMouseOver={playVid} onMouseOut={stopVid}>
                                                    <div className='card__video'>
                                                        <video className='videoElement' muted>
                                                            <source src={game.video} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                    <div className="card__title">
                                                    {game.name}
                                                    </div>
                                                </Link> 
                                            :   null)
                                    }
                                    <img className="arrow-right" src={arrowRight} alt="" onClick={scrollCardsRight}/>
                                    <img className="arrow-left" src={arrowLeft} alt="" onClick={scrollCardsLeft} />
                                </div>
                        }
                        
                    </Fade>
                </div>
            </div>
        </div> 
    );
}