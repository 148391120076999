import axios from "axios";
export const API_URL = process.env.REACT_APP_API_URL + '/api/v1';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export const paymentsApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-type": "application/json",
      "api-key": process.env.REACT_APP_SERVER_API_KEY || "",
    }
});

export default class ApiService{
  static getCurrentLocation() {
    return api.get("https://ipinfo.io/json?token="+process.env.REACT_APP_LOCATION_TOKEN)
  }
  static saveStripeInfo(data={}){
    return api.post(`${API_URL}/save-order/`, data)
  }
  static savePassesInfo(data={}){
    return api.post(`${API_URL}/save-passes/`, data)
  }
  static makePayment(data={}) {
    return paymentsApi.post(`${process.env.REACT_APP_PAYMENT_API_URL}/payments/pay`, data)
  }
  static saveNewPayment(data={}){
    return api.post(`${API_URL}/payment/`, data)
  }
  static getCertificates(){
    return api.get(`${API_URL}/certificates/`)
  }
  static getVouchers(){
    return api.get(`${API_URL}/vouchers/`)
  }
  static getGames(){
    return api.get(`${API_URL}/games/`)
  }
  static getPackages(){
    return api.get(`${API_URL}/packages/`)
  }
  static getCertificate(certId){
    return api.get(`${API_URL}/certificates/${certId}/`)
  }
  static saveApplications(data={}){
    return api.post(`${API_URL}/applications/`, data)
  }
}