import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";

import infoVideo from "../assets/video/Render_scene-.mp4";
import eventback from "../assets/img/event_back_new.webp";
import {
  MainForm,
  Package,
  ExperienceStack,
  MetaDecorator,
  EventForm,
} from "../components";
import ApiService from "../services";
import Fade from "react-reveal/Fade";

const cityInfo = [
  {
    city: "san-diego",
    visible: true,
    cardTitles: [
      'VR Free roam full immersive VR advenure for 40 mins',
      'VR 9D Cinema',
      'VR Laser tag',
      'Lounge zone',
      'VR roller coasters',
      'VR motorcycles',
      '3 Screen Race simulator',
      'Drinks & snacks',
      'PCs consoles',
    ],
    cardClass: 'item',
    videoUrl: 'https://www.youtube.com/embed/kWLWU4kD0YM',
    discount: false,
  },
  {
    city: "hollywood",
    visible: false,
    cardClass: 'item',
    discount: false,
  },
  {
    city: "battleground-la",
    visible: true,
    cardTitles: [
      'VR Free roam',
      'VR Rocket',
      'VR Laser tag',
      'VR Party Room',
      'VR Roller coasters',
      'Karaoke',
      'Food & Drinks',
      'VR Station',
      'VR Motorcycles',
    ],
    cardClass: 'losItem',
    videoUrl: 'https://www.youtube.com/embed/CkGHjgm5sx8',
    discount: true,
  },
  {
    city: "battleground-sf",
    visible: false,
    cardTitles: [
      'VR Free roam',
      'VR Rocket',
      'VR Laser tag',
      'VR Party Room',
      'VR Roller coasters',
      'Karaoke',
      'Food & Drinks',
      'VR Station',
      'VR Motorcycles',
    ],
    cardClass: 'losItem',
    videoUrl: 'https://www.youtube.com/embed/CkGHjgm5sx8',
    discount: true,
  }
];

export function Event({ city, stripePromise }) {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [currentCityInfo, setCurrentCityInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    ApiService.getPackages()
      .then((response) => response.data)
      .then((data) => {
        setPackages(data);
      });
    getCityInfo(city);
  }, [city]);

  function getCityInfo(currentCity) {
    cityInfo.forEach((info) => {
      if (info.city === currentCity) setCurrentCityInfo(info);
    });
  }

  return (
    <div className="event">
      <MetaDecorator
        description={`VR EXPERIENCE TO REMEMBER VR BIRTHDAY & PARTY VENUE! 
            Host your unforgettable party at the MOST PROGRESSIVE VR gaming center!`}
        title={`VR Events | ${city.toUpperCase()}`}
        imageUrl={eventback}
        imageAlt={"Anvio Park Events"}
        pageUrl={`https://anviopark.com/${city}/event`}
      />
      <div className="cover">
        <div className="main">
          <div className="container">
            <div className="main__inner">
              <div className="main__left">
                <div>
                  <Fade left distance="50px">
                    <div className="main-title">VR EXPERIENCE TO REMEMBER</div>
                    <div className="secondary-title" style={{ marginTop: 0 }}>
                      VR BIRTHDAY & PARTY VENUE
                    </div>
                  </Fade>
                  <Fade left distance="50px">
                    <div className="description">
                      Host your unforgettable party at the MOST PROGRESSIVE VR
                      gaming center!
                    </div>
                  </Fade>
                  {
                    currentCityInfo.visible ? 
                      <Fade cascade left distance="50px">
                        <a href='#package'><button className="btn btn-primary" style={{marginTop: "50px"}}>Choose package</button></a>
                      </Fade> : null
                  }
                </div>
              </div>
              <div className="main__right">
                <Fade bottom distance="50px">
                    <MainForm
                      selected={selectedPackage}
                      city={city}
                      formTitle="Tell us about your group"
                    />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentCityInfo.visible ? (
        <div>
          <div className="experience">
            <div className="container">
              <div className="experience__inner">
                <Fade left distance="50px">
                  <div className="secondary-title" style={{ maxWidth: "50vw" }}>
                    <p>In here you get together to experience the IMPOSSIBLE</p>
                  </div>
                </Fade>
                <div className="event_secondback">
                  <img src={eventback} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="event_description">
              <Fade left distance="50px">
                <div className="secondary-title description_text">
                  IMMERSE YOURSELF IN THE WORLD OF VIRTUAL REALITY
                </div>
              </Fade>
              <Fade left distance="50px">
                <div className="description description_text">
                  <p>
                    Play together, divide into groups, or have a contest! We’ll
                    do our best to meet your particular needs as well — from
                    allowing bringing your own food to organizing catering and
                    music.
                  </p>
                </div>
              </Fade>
            </div>
          </div>

          <div className="visualization">
            <div className="container">
              <Fade right distance="50px" cascade>
                <div className="visualization__inner">
                  <div className={`visualization__item ${currentCityInfo.cardClass}1`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[0]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}2`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[1]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}3`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[2]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}4`}>
                    <div className="visualization__item--title">
                     {currentCityInfo.cardTitles[3]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}5`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[4]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}6`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[5]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}7`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[6]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}8`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[7]}
                    </div>
                  </div>
                  <div className={`visualization__item ${currentCityInfo.cardClass}9`}>
                    <div className="visualization__item--title">
                      {currentCityInfo.cardTitles[8]}
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>

          <div className="video">
            <div className="container">
              <Fade left distance="50px">
                <div className="video__inner">
                  <iframe
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "16/9",
                    }}
                    width="1280"
                    height="720"
                    src={`${currentCityInfo.videoUrl}?autoplay=1&controls=1&modestbranding=0&playsinline=1&mute=1`}
                    showinfo="0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                    allowFullScreen
                  ></iframe>
                </div>
              </Fade>
            </div>
          </div>

          <div  id="package" className="packages">
            <div className="container">
              <Fade left distance="50px">
                <div className="secondary-title after">Choose your package</div>
              </Fade>
              {
                currentCityInfo.discount ? 
                <Fade bottom distance="40px">
                  <div className="discount-title"><b style={{color: "#cc2027"}}>20%</b> off ANY Party package for MONDAY-FRIDAY</div>
                </Fade> 
                : null
              }
              <div className="packages__inner">
                {packages.length > 0
                  ? packages.sort((a, b) => a.people - b.people).map((pack, index) => pack.id != 10 || city === "san-diego" ? (
                    <Fade bottom distance="50px" key={index}>
                      <Package
                        pack={pack}
                        selected={selectedPackage}
                        setSelected={setSelectedPackage}
                        setOpen={setIsModalOpen}
                      />
                    </Fade>
                  ) : null)
                  : null}
              </div>
            </div>
          </div>

          {isModalOpen ? <Fade duration={400}>
            <div className="modal passes__form club">
              <div style={{width: '100%', height: '100%', position: 'absolute', zIndex: -1}} onClick={() => setIsModalOpen(!isModalOpen)}></div>
              <MainForm
                selected={selectedPackage}
                city={city}
                formTitle="Tell us about your group"
              />
            </div>
          </Fade> : null}

          <ExperienceStack stackTitle={"Book your VR adventure"} city={city} />
        </div>
      ) : (
        <ExperienceStack stackTitle={"Book your VR adventure"} city={city} />
      )}
    </div>
  );
}
