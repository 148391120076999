import React from 'react';

export function NotFound() {
  return (
      <div className='container'>
        <div className='main-title' style={{height: "80vh", top: "30vh"}}>
          404 NotFound
        </div>
      </div>
  );
}
