import React, { useState, useEffect } from "react";
// import { Link, useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import Fade from "react-reveal/Fade";
import { CertificateForm } from "../components";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import { MetaDecorator } from "../components";

import ApiService from "../services";
import CertBack from "../assets/img/webp/cert-main.webp";
import CertChild1 from "../assets/img/parallax_cert.png";

export function Certificates({ city, stripePromise }) {
  const [certificates, setCertificates] = useState(null);
  const [vouchers, setVouchers] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const [selectedVoucher, setSelectedVoucher] = useState({});

  useEffect(() => {
    ApiService.getCertificates()
      .then((response) => response.data)
      .then((data) => {
        setCertificates(data);
        setSelectedCertificate(data[0]);
      });
    ApiService.getVouchers()
      .then((response) => response.data)
      .then((data) => {
        setVouchers(data);
        setSelectedVoucher(data[0]);
      });
  }, []);

  const handleSelectedCertificate = (certificate) => {
    setSelectedCertificate(certificate);
  };

  const handleSelectedVoucher = (voucher) => {
    setSelectedVoucher(voucher);
  };

  return (
    <>
      <MetaDecorator
          description=
              {
              `Gift Certificates to Express Love Support Peace. Let's make each other happy!`
              }
          title={`VR Certificates | ${city.toUpperCase()}`}
          imageUrl={CertBack}
          imageAlt={"Anvio Park VR Certificates"}
          pageUrl={`https://anviopark.com/${city}/certificates`}
      />
      <div className="certificates">
        <div className="container">
          <div className="certificates__inner">
            <Fade left distance="50px">
              <div className="main-title">
                Gift Certificates to Express Love Support Peace
              </div>
            </Fade>
            <Fade left delay={100} distance="50px">
              <div className="description">Make each other happy</div>
            </Fade>
          </div>
        </div>

        <MouseParallaxContainer
          className="cert_paralax_background"
          useWindowMouseEvents
        >
          <MouseParallaxChild
            className="cert_back"
            factorX={0.001}
            factorY={0.001}
          >
            <img
              src={CertBack}
              alt="a"
              style={{ height: "100%"}}
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            className="cert_parallax_child"
            factorX={0.05}
            factorY={0.07}
          >
            <img src={CertChild1} alt="d" />
          </MouseParallaxChild>
        </MouseParallaxContainer>

        <div className="certificates__footer"></div>
      </div>
      <div className="gifts">
        <div className="container">
          <div className="gifts__inner">
            <div className="gifts__head">
              <Fade top>
                <div className="title">CHOOSE YOURE CERTIFICATE</div>
              </Fade>
            </div>
            <Fade right cascade>
              <div className="cards">
                {certificates && certificates.length > 0 &&
                  certificates.map((certificate, index) => (
                    <div
                      className="card"
                      key={index}
                      style={{
                        backgroundImage: `url("${certificate.image_512}")`,
                      }}
                    >
                      <div className="card__title"></div>
                      <div
                        className={`btn card__buy ${
                          selectedCertificate.id === certificate.id
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleSelectedCertificate(certificate)}
                      >
                        {selectedCertificate.id === certificate.id
                          ? "SELECTED"
                          : "SELECT"}
                      </div>
                    </div>
                  ))}
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className="gift">
        <div className="container">
          <div className="gifts__inner">
            <div className="gift__head">
              <Fade top>
                <div className="title">SELECT A CERTIFICATE VALUE</div>
              </Fade>
            </div>
            <Fade right cascade>
              <div className="vouchers">
                {vouchers && vouchers.length > 0 &&
                  vouchers.map((voucher, index) => (
                    <div
                      className={`voucher ${
                        selectedVoucher.id === voucher.id ? "selected" : ""
                      }`}
                      key={index}
                      onClick={() => handleSelectedVoucher(voucher)}
                    >
                      <div className="title">
                        {voucher.name}
                        {voucher.hour === 8 ? (
                          <div className="whole_day">up to 8 hours</div>
                        ) : null}
                        <div className="upToPeopleTitle">
                          <p className="upToPeopleTitle__upto">up to </p>
                          <p className="upToPeopleTitle__people">
                            {voucher.people} people
                          </p>
                        </div>
                      </div>
                      <div className="cert_price">
                        ${String(voucher.price.toLocaleString())}
                      </div>
                    </div>
                  ))}
              </div>
            </Fade>
          </div>
        </div>
        <div className="modal__content">
          {selectedCertificate && selectedVoucher ? (
            <Fade top distance="50px">
              <div className="modal__title">PURCHASE DATA</div>
              <div className="form_body">
                <Elements stripe={stripePromise}>
                  <CertificateForm
                    certificate={selectedCertificate}
                    voucher={selectedVoucher}
                    city={city}
                  />
                </Elements>
              </div>
            </Fade>
          ) : (
            <div className="warning">
              <div className="container">
                NO DATA: Please add certificate and voucher from admin panel
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
