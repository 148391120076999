import React, { useState, useEffect } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { EventForm } from "../components";

export function EventPaymentPage({city, stripePromise}) {
    const price = 59;

    return (
        <div className='club'>
            <div className='modal passes__form'>
                <Elements stripe={stripePromise}>
                    <EventForm price={price} city={city} eventName="Halloween Party"/>
                </Elements>
            </div>
        </div>
    );
}