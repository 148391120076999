import React, {Fragment, useState, useEffect} from 'react'
import Fade from 'react-reveal/Fade'

let count = 1;
let timeout = 1500;

let arr = [
    {
        author: "Alex Ali",
        title: "Perfect adventure!! I recommend it for everyone. Zombies and guns like real =)",
    },
    {
        author: "Jacob S",
        title: "Amazing experience, super cool stuff!"
    },
    {
        author: "K'Shara",
        title: "Very cool experience! I advise everyone."
    },
    {
        author: "Katerine Chaika",
        title: "Very cool and friendly place, we had a lot of fun. I will gladly recommend it to my friends :)"
    },
    {
        author: "Diana Tomashchuk",
        title: "Very cool. We walk for the second time. Fun. Impressive. Emotions are unusual. Adrenalin!"
    }
]

export function ReviewReveal() {

    const [visible, setVisible] = useState(false);
    const [review, setReview] = useState({author: "Artur Beck", title: "a",});

    useEffect(() => {
        setTimeout(() => {
            timeout = visible ? 1500 : 3000;
            setVisible(!visible);
            cycleArray();
        }, timeout);
        
        function cycleArray() {
            let index = count % arr.length;
            setReview(arr[index]);
            count+=1;
        }
    }, [visible]);

    return (
        <Fragment>
            <div className="reviews">
                <div className="container">
                    <Fade top> 
                        <div className="secondary-title after">
                            Reviews
                        </div>
                    </Fade>
                    <div className="reviews__inner">
                        {  
                            <Fade top cascade opposite when={visible}>
                                <div className="review">
                                    <div className="review__author">{review.author}</div>
                                    <div className="review__title">“{review.title}”</div>
                                </div>
                            </Fade>
                        }
                    </div>
                </div>
            </div>      
        </Fragment>
    );
}