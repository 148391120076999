import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import LogoIcon from "../assets/svg/logo_icon.svg";
import LogoText from "../assets/svg/logo_txt.svg";
import battleGroundLaLogo from "../assets/img/battleground-los-angeles.webp";
import battleGroundSfLogo from "../assets/img/battleground-san-francisco.webp";

const cityInfo = [
  { 
    city: 'san-diego',
    address: "3030 Plaza Bonita Road Westfield, Plaza Bonita suite 2530, National City, CA 91950",
    phone: "Phone: +1 (858) 910-3820",
    email: "anviosandiego@gmail.com",
    rights: "Anvio VR",
    instagram: "https://www.instagram.com/anviopark_usa",
    tiktok: "https://www.tiktok.com/@anviopark_usa",
  },
  {
    city: 'hollywood',
    address: "6801 Hollywood Blvd STE #111, 109, Hollywood, CA 90028",
    phone: "Phone: +1 (310) 803-9717",
    email: "anviovr.usa1@gmail.com",
    rights: "Anvio VR",
    instagram: "https://www.instagram.com/anviovr_hollywood/",
    tiktok: "https://www.tiktok.com/@anviovr_hollywood",
  },
  {
    city: 'battleground-la',
    address: "1815 Hawthorne Blvd Ste. 202, Redondo Beach, CA 90278",
    phone: "Phone: +1 (424) 999-8272",
    email: "battleground.vr@gmail.com",
    rights: "Battleground VR - Los Angeles",
    instagram: "https://www.instagram.com/anviovr_hollywood/",
    tiktok: "https://www.tiktok.com/@anviovr_hollywood",
  },
  {
    city: 'battleground-sf',
    address: "2801 Leavenworth St #17, San Francisco, CA 94133, USA",
    phone: "Phone: +1 (949) 795-2010",
    email: "battleground.vr.sf@gmail.com",
    rights: "Battleground VR - San Francisco",
    instagram: "https://www.instagram.com/anviovr_hollywood/",
    tiktok: "https://www.tiktok.com/@anviovr_hollywood",
  }
];

export function Footer({city, setCity}) {
  const [currentCityInfo, setCurrentCityInfo] = useState([cityInfo[0]]);

  const navigate = useNavigate();
  useEffect(() => {
     getCityInfo(city);
   }, [city]);

   const SetCity = (city) => {
    localStorage.setItem("localCity", city);
    navigate(city);
    setCity(city);
  };

  function getCityInfo(currentCity) {
    cityInfo.forEach(info => {
      if(info.city === currentCity) setCurrentCityInfo(info);
    });
  }
 
  return (
    <footer className="footer">
        <div className="container">
          <div className="footer__inner">
            <div className="footer__info">
              {
                city !== "battleground-la" && city !== "battleground-sf"
                ? <Link to={`/${city}`} className="header__logo">
                    <img src={LogoIcon} className="logo_icon" />
                    <img src={LogoText} className="logo_text" />
                  </Link>
                : <Link to={`/${city}`} >
                    <img src={city == "battleground-la" ? battleGroundLaLogo : battleGroundSfLogo} style={{marginTop: "-20px"}} className="battleground_logo" alt="battleground logo" />
                  </Link>
              }
              <div className="footer__desc">
                © 2023 {currentCityInfo.rights} | All rights reserved
              </div>
              <div className="footer__desc" >
                {currentCityInfo.address}
              </div>
              <div className="footer__desc" >
                {currentCityInfo.phone}
              </div>
              <div className="footer__desc" >
                {currentCityInfo.email}
              </div>
            </div>
            <div className="footer__items">
              <div className="footer__item">
                <div className="title">Locations</div>
                <div className="nav">
                  <div onClick={() => SetCity("san-diego")} className="nav__link" >San Diego</div>
                  <div onClick={() => SetCity("hollywood")} className="nav__link" >Hollywood</div>
                  <div onClick={() => SetCity("battleground-la")} className="nav__link" >Los Angeles</div>
                  <div onClick={() => SetCity("battleground-sf")} className="nav__link" >San Francisco</div>
                </div>
              </div>
              <div className="footer__item">
                <div className="title">Experiences</div>
                <div className="nav">
                  <Link to={`/${city}/experiences/CITYZ`} className="nav__link">City Z</Link>
                  <Link to={`/${city}/experiences/CITYZ.Survivors`} className="nav__link">City Z. Survivor</Link>
                  <Link to={`/${city}/experiences/CITYZ.Antidote`} className="nav__link">City Z. Antidote</Link>
                  <Link to={`/${city}/experiences/STATIONZARYA`} className="nav__link">Station Zarya</Link>
                  <Link to={`/${city}/experiences/LOSTSANCTUARY`} className="nav__link">Lost Sanctuary</Link>
                  <Link to={`/${city}/experiences/DragonVR`} className="nav__link">Dragon VR</Link>
                  { city !== "hollywood" ? <Link to={`/${city}/experiences/Versus`} className="nav__link">Versus</Link> : null}
                </div>
              </div>
              <div className="footer__item">
                <div className="title">Resources</div>
                <div className="nav">
                  <Link to={`/${city}/FAQ`} className="nav__link">FAQ</Link>
                </div>
              </div>
              <div className="footer__item">
                <div className="title">Social</div>
                <div className="nav">
                  <a href={currentCityInfo.instagram} className="nav__link">Instagram</a>
                  <a href={currentCityInfo.tiktok} className="nav__link">TikTok</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
}
