import React from "react";

export function Pass({ pass, selected, setSelected }) {
  return (
    <div className={`pass ${selected.id === pass.id ? 'selected' : ''}`} onClick={() => setSelected(pass)}>
      <div className="pass__inner">
        <div className="pass__additionaly">
          <div className="pass__additionaly--title">{pass.name}</div>
          <div className="pass__additionaly--ul">
            <div className="pass__additionaly--li">{pass.time}</div>
          </div>
        </div>
        <div className="pass__price">${(pass.price).toLocaleString()}</div>
      </div>
    </div>
  );
}
