import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ExperienceStack } from '../components/ExperienceStack';
import Fade from "react-reveal/Fade";
import { MetaDecorator } from "../components";

import mainBg from "../assets/img/faq_main.webp";

const cityInfo = [
  { 
    city: 'san-diego',
    priceInfo: 'Our prices are $45 per person for weekdays and $50 per person for weekends.',
    phone: "+1 (858) 910-3820",
  },
  {
    city: 'hollywood',
    priceInfo: 'Our prices are $50 per person for weekdays and $55 per person for weekends.',
    phone: "+1 (310) 803-9717",
  },
  {
    city: 'battleground-la',
    priceInfo: 'Our price is $45 per person.',
    phone: "+1(424) 999-8272",
  },
  {
    city: 'battleground-sf',
    priceInfo: 'Our price is $45 per person.',
    phone: "+1 (949) 795-2010",
  }];

export function FAQ({ city }) {
  const screenWidth = window.screen.width;
  const [currentCityInfo, setCurrentCityInfo] = useState([cityInfo[0]]);
  const [isOpen, setIsOpen] = useState([{tag: "", state: false}]);

  useEffect(() => {
    getCityInfo(city);
  }, [city]);

  function getCityInfo(currentCity) {
    cityInfo.forEach(info => {
      if(info.city === currentCity) setCurrentCityInfo(info);
    });
  }

  function toggleQuestionVisibility(event){
    if(event.target.firstElementChild.className === "description_container"){
      if(event.target.firstElementChild.style.maxHeight === "300px" ) {
        event.target.firstElementChild.style.maxHeight = 0;
      } else {
        event.target.firstElementChild.style.maxHeight = "300px";
      }
    }
  }

  return (
    <div className="faq">
      <MetaDecorator
        description={`Frequently Asked Questions on Anvio Park`}
        title={`FAQ Anvio Park | ${city.toUpperCase()}`}
        imageUrl={mainBg}
        imageAlt={"Anvio Park FAQ"}
        pageUrl={`https://anviopark.com/${city}/FAQ`}
      />
      <div className="main_background">
        <img src={mainBg} alt="" style={{filter: "opacity(100%)"}}/>
      </div>
      <div className="main" style={{background: "unset"}}>
        <div className="container">
          <div className="main__inner">
            <div className="left">
              <Fade left distance="50px"><div className="main-title" >Frequently Asked Questions</div></Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="faq__body">
        <div className="container">
          <div className="faq__class">
            <Fade left distance="50px"><div className="secondary-title after">Booking & Pricing</div></Fade>
            <Fade top cascade>
              <div className="questions">
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  How much is a ticket?
                  <div className="description_container">
                    {
                      currentCityInfo.city === 'battleground-la' ? 
                      <ul className="faq__description">
                        Our prices:
                        <li>For an Escape room the price is $45 per person.</li>
                        <li>Unlimited ticket - includes Escape room, VR Laser tag and other VR attractions is $60 per hour per person.</li>
                        <li>You also can purchase every single VR attraction separately.</li>
                      </ul> :
                      <ul className="faq__description">
                        {currentCityInfo.priceInfo}
                        <li>You can modify the number of players that want to take part in the adventure. All additional charges for additional players can be paid on-site upon arrival.</li>
                        <li>The game can be changed up to the start of your reservation.</li>
                      </ul>
                    }
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  Interested in extending your playtime in VR?
                  <div className="description_container">
                    <ul className="faq__description">
                      Give us a call to discuss "Back to Back" bookings. Play any 2 games for a discounted rate on the second booking (subject to availability.)
                      Please call the store if you have any questions on back to back pricing.
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  Is it possible to change or cancel a reservation?
                  <div className="description_container">
                    <ul className="faq__description">
                        Unfortunately, we don’t issue the refunds.
                        We can offer you to reschedule your game for another day convenient for you or we will issue a gift certificate to a future booking.
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  How do I book for a large group?
                  <div className="description_container">
                    {
                      currentCityInfo.city === 'battleground-la' ? 
                      <ul className="faq__description">
                        For a large groups please check the group packages here: <div style={{width: "800px"}}></div>
                        <Link
                          style={{color: "white", textDecoration: "underline"}}
                          to="/battleground-la/event" state={{ fromFAQ: true }}>
                          https://anviopark.com/battleground-la/event#packages
                        </Link>
                      </ul> :
                      <ul className="faq__description">
                        The groups of up to 12 people can book the adventure using our booking system. 
                        You will be divided into two groups. You will play at two arenas at the same time.
                        <br/><br/>
                          If you'd like us to help book a group of more than 12 people, 
                          please feel free to connect with the store directly by phone with any questions. 
                          Our phone number is {currentCityInfo.phone}.  
                          You can also find our phone number on our  website or on Google or Yelp.
                      </ul>
                    }
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  What is the returns and refunds policy of E-Gift Card?
                  <div className="description_container">
                    <ul className="faq__description">
                      All Sales are Final. Gift cards are nonrefundable. 
                      Lost, misplaced or stolen gift cards are non-refundable, replaceable, nor valid. 
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
              </div>
            </Fade>
          </div>

          <div className="faq__class">
            <div className="secondary-title after">The Experience</div>
            <Fade top cascade>
              <div className="questions">
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  Is photo-taking allowed?
                  <div className="description_container">
                    <ul className="faq__description">
                      Yes! We would love for you to share your experience with your friends and loved ones. You can ask your host to take photos and videos of you during the session.
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  What should I wear?
                  <div className="description_container">
                    <ul className="faq__description">
                      We recommend that you dress in light, comfortable clothing. 
                      This is an active experience, so wear something that won't restrict your ability to move around the space.
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  Do you make your own games?
                  <div className="description_container">
                    <ul className="faq__description">
                      We make our own experiences. Only at our place you can experience 
                      a real life emotions of fighting with zombies in a post-apocalyptic world, 
                      or being the part of a cosmic crew, or visit a fairy dragon's world .
                      <br />
                      We are doing a super exclusive game content. So, nowhere else in the whole US you can try these experiences. Only at ANVIO VR.
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  How does the ANVIO VR experience work?
                  <div className="description_container">
                    <ul className="faq__description">
                      One game takes place in a ​​1000 sq.feet arena.
                      Which means that you don't have an illusion of movement around, as it usually happens in a VR.
                      Each step that you do in the game, you do in real life. So, plenty of space just for your incredible "like-real" experience.
                      <br />
                      It is totally different from the experience that you may have at home with an ordinary VR home- headset. 
                      <br />
                      We have the most powerful computers for each player, motion trackers, haptic equipment, etc. 
                      Using this latest VR hardware helps us deliver "like no other" VR experience! 
                      So, if you were attacked by zombies or aliens you will definitely feel it.
                      <br />
                      Trust us, the only way to truly understand it is to try it by yourself.
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  Do you have a minimum age requirement?
                  <div className="description_container">
                    <ul className="faq__description">
                      <li>3 Years or older.</li>
                      <li>Able to walk unassisted for 30 minutes continuously with full use of both hands.</li>
                      <li>
                        For guests under 18 years old, a parent/guardian must be physically 
                        present in the store during the session to sign a minor waiver form.
                      </li> <br />
                      <b style={{paddingLeft: 15}}>We do not recommend this experience to pregnant women or those with heart conditions.</b>
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
                <div className="faq__question" onClick={toggleQuestionVisibility}>
                  How long is the experience?
                  <div className="description_container">
                    <ul className="faq__description">
                      Please plan for a total of about 1 hour for your ANVIO VR experience. It includes:
                      <li>
                        Arrival to check in with your host(ess) 10 minutes prior to you 
                        booking time to ensure your ANVIO VR experience does not get cut short.
                      </li>
                      <li>About 15 minutes of training and setup.</li>
                      <li>About 30 minutes in the VR experience</li>
                      <li>
                        Another 5 minutes for taking photos and sharing your emotions!
                      </li>
                    </ul>
                  </div>
                  <div className="arrow_down"></div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <ExperienceStack stackTitle={"Trending VR adventures"} city={city}/>
    </div>
  );
}
