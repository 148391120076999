import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ExperienceStack } from '../../components/ExperienceStack';
import Fade from "react-reveal/Fade";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import {MetaDecorator} from "../../components";

import man from "../../assets/img/experiences/station/mansz.png";
import cz1 from "../../assets/img/experiences/station/sz1.webp";
import cz2 from "../../assets/img/experiences/station/sz2.webp";
import cz3 from "../../assets/img/experiences/station/sz3.webp";
import scorp from "../../assets/img/experiences/station/scorp.webp";
import mainBg from "../../assets/img/experiences/station/stationMainBg.webp";
import secondBg from "../../assets/img/experiences/station/bug-dark.webp";
import genre_dec from "../../assets/img/decoration/genre.png";
import people_dec from "../../assets/img/decoration/people.png";
import time_dec from "../../assets/img/decoration/time.png";
import character from "../../assets/img/experiences/station/man.webp";


export function StationZarya({ city }) {
  const [isTrailerOpened, setIsTrailerOpened] = useState(false);
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  
  return (
    <div className="experiences">
      <MetaDecorator
        description=
            {
            `Station Zarya - Now you are part of the landing party, 
            which is sent to the distant planet "Regulus-5"`
            }
        title={`Anvio VR Park - Station Zarya | ${city.toUpperCase()}`}
        imageUrl={mainBg}
        imageAlt={"Anvio Park Station Zarya"}
        pageUrl={`https://anviopark.com/${city}/experiences/STATIONZARYA`}
      />
      <div className="main_background">
        <img src={mainBg} alt="" style={{width: screenWidth}}/>
        <MouseParallaxContainer
        className="paralax_background"
        useWindowMouseEvents
        >
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.07}
          factorY={0.08}
        >
          <img src={character} alt="b"/>
        </MouseParallaxChild>
      </MouseParallaxContainer>
      </div>
      <div style={{position: "absolute", width: "100%", height: "100%"}}>
        <Fade duration={500} when={isTrailerOpened} collapse >
          <div className="trailer" onClick={() => setIsTrailerOpened(false)}>
            <iframe 
              style={{ width: "80%", height: "auto", aspectRatio: "16/9" }}
              width="1280" height="720" 
              src="https://www.youtube.com/embed/_whSH5kxp5A?autoplay=0&controls=1&modestbranding=0&playsinline=1"
              showinfo="0" 
              title="YouTube video player" frameBorder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope;" 
              allowFullScreen></iframe>
          </div>
        </Fade>
      </div>
      <div className="main">
        <div className="container">
          <div className="main__inner">
            <Fade left cascade duration={500} distance="50px">
              <div className="left">
                <div className="main-title">Station Zarya</div>
                <div className="description"><img src={genre_dec} alt="" />Genre: SCI-FI</div>
                <div className="description"><img src={people_dec} alt="" />People: 1-6 people</div>
                <div className="description"><img src={time_dec} alt="" />Session time: 30 mins</div>
                <div className="actions">
                  <Link to={`/${city}/book`} className="btn btn-primary">
                    Book an adventure
                  </Link>
                  <div className="btn btn-secondary" onClick={() => setIsTrailerOpened(true)}>
                    Watch trailer
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="experience" style={{height: screenHeight*0.9}}>
        <div className="container">
          <div className="secondary_background" style={{zIndex: 0}}>
            <img src={secondBg} alt="" style={{width: screenWidth}}/>
          </div>
          <div className="experience__inner">
            <Fade left distance="50px">
              <div className="title">
                «Are you dreaming of space travel? Your time has come!»
              </div>
            </Fade>
            <div className="description">
              <Fade top >
                <p>Now you are part of the landing party, which is sent to the distant planet "Regulus-5".</p>
                <p>
                  But you will not be able to calmly admire the starlight - an SOS signal was received from the station,
                </p>
                <p> and communication with its inhabitants was interrupted</p>
                <p>
                  Who will you meet in outer space?
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      
      <div className="intro">
        <div className="container">
          <div className="intro__inner">
            <Fade left>
              <div className="intro__left" style={{left: -80}}>
                <img
                  style={{height: 700}}
                  src={man}
                  className="img-responsive"
                  alt=""
                />
              </div>
            </Fade>
            <Fade right>
              <div className="intro__right">
                <img
                  src={scorp}
                  className="img-responsive"
                  alt=""
                />
              </div>
            </Fade>
            <Fade right cascade>
              <div className="grid">
                <img
                  src={cz1}
                  className="grid__element"
                  alt=""
                />
                <img
                  src={cz3}
                  className="grid__element"
                  alt=""
                />
                <img
                  src={cz2}
                  className="grid__element"
                  alt=""
                />
                
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="booking">
        <div className="container">
          <Fade top cascade>
            <div className="booking__inner">
              <div className="modal__title">Let's go conquer the space!</div>
              <div>
                <Link to={`/${city}/book`} className="btn btn-primary">
                  Book an adventure
                </Link>
              </div>
            </div>
          </Fade>
        </div>
        <div className="booking__cover"></div>
      </div>
      <ExperienceStack stackTitle={"Trending VR adventures"} city={city}/>
    </div>
  );
}
