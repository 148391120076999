import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'
import { loadStripe } from "@stripe/stripe-js/pure";
import {
  Main,
  Certificates,
  Booking,
  NotFound,
  Experiences,
  Event,
  Thanks,
  CyberClub,
  ComingSoon,
  FAQ,
  Halloween,
  EventPaymentPage,
} from "./pages";

import { Footer, Header } from "./components";
import ApiService from "./services";
const stripePromise1 = loadStripe(process.env.REACT_APP_STRIPE_PK_SAN_DIEGO);
const stripePromise2 = loadStripe(process.env.REACT_APP_STRIPE_PK_HOLLYWOOD);
const stripePromise3 = loadStripe(process.env.REACT_APP_STRIPE_PK_LOS_ANGELES);

//Google Analytics
const gaTrackingId = "G-E9S86TZMC6"; 
ReactGA.initialize(gaTrackingId);

const tagManagerArgs = { gtmId: "GTM-M6RMNBV" }
TagManager.initialize(tagManagerArgs)

const cities = {
  "san diego" : "san-diego",
  "san francisco": "battleground-sf",
  "los angeles" : "battleground-la",
}

export const setCityCookie = (city) => {
  const date = new Date();
  date.setTime(date.getTime() + 72*60*60*1000);
  document.cookie = "localCity="+(city)+"; expires="+date.toUTCString()+"; Secure";
}

function App() {
  let _city = document.cookie.split("; ").find((row) => row.startsWith("localCity"))?.split("=")[1]
  const [city, setCity] = useState(_city?.toLowerCase() || "");
  if(!_city) {
    ApiService.getCurrentLocation().then((response) => {
      if(response.status === 200 && response.data?.city) {
        window.location.replace("./"+(cities[(response.data.city).toLowerCase()] || "san-diego"));
        setCityCookie(cities[(response.data.city).toLowerCase()] || "san-diego")
        setCity(cities[(response.data.city).toLowerCase()] || "san-diego");
      }
    });
  }

  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      ReactGA.send({ 
        hitType: "pageview", 
        page: window.location.pathname + window.location.search 
      });
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  useEffect(() => {
    if(window.location.search === "?home") {
      window.location.replace("./"+(city || "san-diego"));
    } else if (_city !== window.location.pathname.split("/")[1]) {
      localStorage.setItem("localCity", window.location.pathname.split("/")[1]);
      setCity(localStorage.getItem("localCity"));
    } else {
      setCity(_city);
    }
  }, []);

  return (
    <BrowserRouter>
      <Header city={city} setCity={setCity} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to="san-diego?home" replace />} />
        <Route path="san-diego">
          <Route index element={<Main city={city} />} />
          <Route path="book" element={<Booking city={city} />} />
          <Route
            path="event"
            element={<Event city={city} stripePromise={stripePromise2} />}
          />
          <Route
            path="club"
            element={<CyberClub city={city} stripePromise={stripePromise2} />}
          />
          <Route path="thanks" element={<Thanks city={city} />} />
          <Route path="experiences/:id" element={<Experiences city={city} />} />
          <Route path="certificates" city={city}>
            <Route index element={<Certificates city={city} stripePromise={stripePromise2} />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="FAQ" element={<FAQ city={city} />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="hollywood">
          <Route index element={<Main city={city} />} />
          <Route path="book" element={<Booking city={city} />} />
          <Route
            path="event"
            element={<Event city={city} stripePromise={stripePromise2} />}
          />
          <Route path="thanks" element={<Thanks city={city} />} />
          <Route path="experiences/:id" element={<Experiences city={city} />} />
          <Route path="certificates" city={city}>
            <Route index element={<Certificates city={city} stripePromise={stripePromise2} />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="FAQ" element={<FAQ city={city} />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="battleground-la">
          <Route index element={<Main city={city} />} />
          <Route path="book" element={<Booking city={city} />} />
          <Route
            path="event"
            element={<Event city={city} stripePromise={stripePromise2} />}
          />
          <Route
            path="club"
            element={<CyberClub city={city} stripePromise={stripePromise2} />}
          />
          <Route path="thanks" element={<Thanks city={city} />} />
          <Route path="experiences/:id" element={<Experiences city={city} />} />
          <Route path="certificates" city={city}>
            <Route index element={<Certificates city={city} stripePromise={stripePromise2} />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="FAQ" element={<FAQ city={city} />} />
          {/* <Route path="halloween" element={<Halloween city={city} stripePromise={stripePromise2} />} /> */}
          <Route path="eventpayment" element={<EventPaymentPage city={city} stripePromise={stripePromise2} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="battleground-sf">
          <Route index element={<Main city={city} />} />
          <Route path="book" element={<Booking city={city} />} />
          <Route
            path="event"
            element={<Event city={city} stripePromise={stripePromise2} />}
          />
          <Route
            path="club"
            element={<CyberClub city={city} stripePromise={stripePromise2} />}
          />
          <Route path="thanks" element={<Thanks city={city} />} />
          <Route path="experiences/:id" element={<Experiences city={city} />} />
          <Route path="certificates" city={city}>
            <Route index element={<Certificates city={city} stripePromise={stripePromise2} />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="FAQ" element={<FAQ city={city} />} />
          {/* <Route path="halloween" element={<Halloween city={city} stripePromise={stripePromise2} />} /> */}
          <Route path="eventpayment" element={<EventPaymentPage city={city} stripePromise={stripePromise2} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer city={city} setCity={setCity} />
    </BrowserRouter>
  );
}

export default App;
