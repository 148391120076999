import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ExperienceStack } from '../../components/ExperienceStack';
import Fade from "react-reveal/Fade";
import {MetaDecorator} from "../../components";

import mainBg from "../../assets/img/experiences/versus/versus_main.webp"
import genre_dec from "../../assets/img/decoration/genre.png"
import people_dec from "../../assets/img/decoration/people.png"
import time_dec from "../../assets/img/decoration/time.png"

const cityInfo = [
  { 
    city: 'san-diego',
    phone: "+1 (858) 910-3820",
  },
  {
    city: 'battleground-la',
    phone: "+1 (424) 999-8272",
  }
];

export function Versus({ city }) {
  const [isTrailerOpened, setIsTrailerOpened] = useState(false);
  const [currentCityInfo, setCurrentCityInfo] = useState([cityInfo[0]]);
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;

  useEffect(() => {
    getCityInfo(city);
  }, [city]);

  function getCityInfo(currentCity) {
    cityInfo.forEach(info => {
      if(info.city === currentCity) setCurrentCityInfo(info);
    });
  }

  return (
    <div className="experiences">
      <MetaDecorator
          description=
              {
              `Versus - Laser Tag ${city.toUpperCase()}!`
              }
          title={`Anvio VR Park - Versus | ${city.toUpperCase()}`}
          imageUrl={mainBg}
          imageAlt={"Anvio Park Versus"}
          pageUrl={`https://anviopark.com/${city}/experiences/Versus`}
      />
      <div className="main_background">
        <img src={mainBg} alt="" style={{width: screenWidth}}/>
      </div>
      <div style={{position: "absolute", width: "100%", height: "100%"}}>
        <Fade duration={500} when={isTrailerOpened} collapse >
          <div className="trailer" onClick={() => setIsTrailerOpened(false)}>
            <iframe 
              style={{ width: "80%", height: "auto", aspectRatio: "16/9" }}
              width="1280" height="720" 
              src="https://www.youtube.com/embed/cmhOwiZQ8Hk?autoplay=0&controls=1&modestbranding=0&playsinline=1"
              showinfo="0" 
              title="YouTube video player" frameBorder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope;" 
              allowFullScreen></iframe>
          </div>
        </Fade>
      </div>
      <div className="main">
        <div className="container">
          <div className="main__inner">
            <Fade left cascade duration={500} distance="50px">
              <div className="left">
                <div className="main-title">&nbsp;</div>
                <div className="description"><img src={genre_dec} alt="" />Genre: Action/PvP</div>
                <div className="description"><img src={people_dec} alt="" />People: 2-6 people</div>
                <div className="description"><img src={time_dec} alt="" />Session time: 18 mins</div>
                <div className="actions">
                  <a href={`tel:${currentCityInfo.phone}`} className="btn btn-primary">
                    Call us to book
                  </a>
                  <div className="btn btn-secondary" onClick={() => setIsTrailerOpened(true)}>
                    Watch trailer
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="experience" style={{height: screenHeight}}>
        <div className="secondary_background">
          <video className='main-video' loop muted autoPlay playsInline preload="auto" style={{width: screenWidth}}>
          </video>
        </div>
        <div className="container">
            <div className="experience__inner">
              <Fade left distance="50px">
                <div className="title">
                  «Enter the arena for PVP battles!»
                </div>
              </Fade>
              <div className="description">
                <Fade top >
                  <p>Versus is Phenomena's latest creation: a retro-futuristic laser tag in virtual reality.</p>
                  <p>
                  Enter the arena for PVP battles with your V-99 blaster and photon Barrier, 
                  or use the Giga-Beam to smash your opponents! 
                  </p>
                  <p> The rules are simple: a 18-minute battle where the highest score wins.</p>
                  <p>
                    1vs1 or 2vs2 or 3vs3 mode, fights in Versus’ Arena are intense and fun !
                  </p>
                </Fade>
              </div>
            </div>
        </div>
      </div>
      <ExperienceStack stackTitle={"Trending VR adventures"} city={city}/>
    </div>
  );
}
