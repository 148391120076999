import React, { useState, useEffect } from 'react';
import {MetaDecorator} from "../components";
import { ThreeDots } from 'react-loader-spinner';

export function Booking({city}) {
    const [currentCityCode, setCurrentCityCode] = useState({});
    const cityCode = [
        {
            city: 'san-diego',
            code: 'sd',
        },
        {
            city: 'hollywood',
            code: 'hollywood',
        },
        {
            city: 'battleground-la',
            code: 'la',
        },
        {
            city: 'battleground-sf',
            code: 'SF',
        }
    ]

    function getCityCode(currentCity) {
        cityCode.forEach((info) => {
          if (info.city === currentCity) return setCurrentCityCode(info.code);
        });
    }

    useEffect(() => {
        getCityCode(city);
    }, [city]);

    return (
        <div className='booking_main'>
            <MetaDecorator
                description=
                    {
                    `Book your VR adventure at Anvio Park!`
                    }
                title={`Booking VR adventure | ${city.toUpperCase()}`}
                imageUrl={""}
                imageAlt={"Anvio VR Park Booking"}
                pageUrl={`https://anviopark.com/${city}/book`}
            />
            <div className='booking_cover'></div>
            <div className='booking_container'>
                <iframe className='booking_iframe' src={`https://13.57.178.111.nip.io/booking`} frameBorder="0" title='booking'></iframe>
            </div>
            <ThreeDots height="100%" width="100%" radius="1" color="white" ariaLabel="loading" visible={true} 
            wrapperStyle={{position: "absolute", top: "50%", left: "40%", width: "calc(5vh + 10vw)", zIndex: -1}}/>
        </div>
    );
}