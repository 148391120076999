import React, { useEffect, useState } from 'react';
import { Elements } from "@stripe/react-stripe-js";


import pcImg from "../assets/img/discover/pc.png";
import psImg from "../assets/img/discover/ps.png";
import reclinerImg from "../assets/img/discover/recliner.png";
import switchImg from "../assets/img/discover/switch.png";
import { MetaDecorator } from "../components";

import pcVideo from "../assets/video/discover/PC_600.mp4";
import ps5Video from "../assets/video/discover/PS5_600.mp4";
import seatVideo from "../assets/video/discover/Seat_600.mp4";
import switchVideo from "../assets/video/discover/Nintendo_600.mp4";


import pcIcon from "../assets/img/discover/partners/pcgame.png";
import ps5Icon from "../assets/img/discover/partners/ps5.png";
import switchIcon from "../assets/img/discover/partners/switch.png";
import xboxIcon from "../assets/img/discover/partners/xbox.png";

import { ExperienceStack, PassesForm, Pass } from '../components';
import Fade from "react-reveal/Fade";

const passes = [
    {
        id: 1,
        name: "1 hour",
        time: 'hourly',
        price: 9
    },
    {
        id: 2,
        name: "Day pass",
        time: 'daily',
        price: 25
    },
    {
        id: 3,
        name: "Weekly pass",
        time: 'all week',
        price: 49
    },
    {
        id: 4,
        name: "Monthly pass",
        time: 'unlimited',
        price: 149
    }
]

export function CyberClub({ city, stripePromise }) {
    const [selectedPass, setSelectedPass] = useState({});
    const [open, setOpen] = useState(false);

    // useEffect(() => {
    //     // setSelectedPass(passes[0])
    // }, [])

    const handleSelectedPass = (pass) => {
        setSelectedPass(pass);
        setOpen(true);
    };

    return (
        <div className='club'>
            <MetaDecorator
                description=
                {
                    `Level up your next hang out with friends and family at our Cyber Club!`
                }
                title={`Anvio Cyber Club | ${city.toUpperCase()}`}
                imageUrl={switchImg}
                imageAlt={"Anvio Park Cyber Club"}
                pageUrl={`https://anviopark.com/${city}/club`}
            />
            <div className='cyber'>
                <div className="cyber__cover">
                    <div className="container">
                        <div className="cyber__inner">
                            <div className="cyber__left">
                                <Fade left distance="50px">
                                    <div className="main-title">CYBER CLUB</div>
                                </Fade>
                                <Fade left distance="50px">
                                    <div className="desc">Level up your next hang out with friends and family</div>
                                </Fade>
                                <Fade cascade left distance="50px">
                                    <a href='#passes'><button className="btn btn-primary">Book your spot</button></a>
                                    <small>starting $9 per person</small>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infomation">
                <div className="container">
                    <Fade top distance="50px" cascade>
                        <div className="infomation__inner">
                            <p>ANVIO VR Cyber Club is an ultimate gaming club and lounge zone.</p>
                            <p>Play your fav games in network with your friends on the most powerful PCs and relax at our massage recliners.</p>
                            <p>Have a snack at our lounge zone and enjoy the latest consoles on the largest screens.</p>
                            <p>Level up your next hang out with friends and family!</p>
                        </div>
                    </Fade>
                    <div className="discover">
                        <Fade left distance="50px">
                            <div className="secondary-title">
                                Discover what we have:
                            </div>
                        </Fade>
                        <div className="discover__grid">
                            <Fade top distance="50px">
                                <div className="discover__item">
                                    <div className="discover__item--title">
                                        Gaming PCs
                                    </div>
                                    <video src={pcVideo} alt="" loop muted autoPlay playsInline preload="auto" />
                                    <div className="discover__item--starting btn btn-secondary">starting $9</div>
                                </div>
                            </Fade>
                            <Fade top distance="50px">
                                <div className="discover__item">
                                    <div className="discover__item--title">
                                        PS5
                                    </div>
                                    <video src={ps5Video} alt="" loop muted autoPlay playsInline preload="auto" />
                                    <div className="discover__item--starting btn btn-secondary">starting $9</div>
                                </div>
                            </Fade>
                            <Fade top distance="50px">
                                <div className="discover__item">
                                    <div className="discover__item--title">
                                        Nintendo switch
                                    </div>
                                    <video src={switchVideo} alt="" loop muted autoPlay playsInline preload="auto" />
                                    <div className="discover__item--starting btn btn-secondary">starting $9</div>
                                </div>
                            </Fade>
                            <Fade top distance="50px">
                                <div className="discover__item">
                                    <div className="discover__item--title">
                                        Massage recliner
                                    </div>
                                    <video src={seatVideo} alt="" loop muted autoPlay playsInline preload="auto" />
                                    <div className="discover__item--starting btn btn-secondary">starting $9</div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <div className='zone'>
                <div className='zone__cover'>
                    <div className="container">
                        <div className="zone__inner">
                            <div className="zone__left">
                                <Fade left distance="50px">
                                    <div className="secondary-title after">LOUNGE ZONE</div>
                                    <div className="desc">includes Nintendo switch, PS5</div>
                                    <div className="btn btn-secondary" style={{width: "fit-content", marginTop: "20px"}}>$14 per peson</div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="passes">
                <div className="container">
                    <div className="passes__inner">
                        <Fade left distance="50px">
                            <div className="secondary-title after">CYBER CLUB</div>
                        </Fade>
                        <Fade top distance="50px" cascade>
                            <div className="partners">
                                <img src={pcIcon} alt="" className='partners--logo' />
                                <img src={ps5Icon} alt="" className='partners--logo' />
                                <img src={switchIcon} alt="" className='partners--logo' />
                                <img src={xboxIcon} alt="" className='partners--logo' />
                            </div>
                        </Fade>
                        <Fade left distance="50px">
                            <div className="desc" id='passes'>Choose your PASS</div>
                        </Fade>
                        <div className="passes__list">
                            {passes ? passes.map((pass, index) => <Fade bottom distance="50px" key={index}>
                                <Pass pass={pass} selected={selectedPass} setSelected={handleSelectedPass} />
                            </Fade>) : null}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="snack">
                <div className="snack__left">
                    <div className="snack__left--title">Snack box</div>
                    <div className="snack__left--desc">Each box contains 17-20 full-sized items, perfect for your group to share!</div>
                </div>
                <div className="snack__right">
                    <img src="" alt="" className='snack__right--img' />
                    <div className="snack__right--price">$50</div>
                </div>
            </div> */}
            {open ? <Fade duration={400}>
                <div className="modal passes__form" onClick={() => setOpen(!open)}>
                    <Elements stripe={stripePromise}>
                        <PassesForm pass={selectedPass} city={city} open={open} setOpen={setOpen} />
                    </Elements>
                </div>
            </Fade> : null}

            <ExperienceStack city={city} stackTitle={"Book your VR adventures"} ></ExperienceStack>
        </div>

    );
}