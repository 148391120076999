import React from 'react';

export function Thanks() {

    return (
        <div className='thanks'>
            <div className='container'>
                <div className='thank'>
                    Thank you for choosing Anvio VR!
                </div>
            </div>
        </div>
    );
}