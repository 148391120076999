import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const MetaDecorator = ({ title, description, imageUrl, pageUrl }) => (
  <Helmet>
    <title>{title}</title>
    <meta itemprop="name" data-rh="true" content={title}/>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={"https://anviopark.com" + imageUrl} />
    <meta
      property="og:url"
      content={"https://anviopark.com" + window.location.pathname + window.location.search}
    />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:card" content={"https://anviopark.com" + imageUrl} />
    <meta name="twitter:image" content={"https://anviopark.com" + imageUrl} />
    <meta name="twitter:image:alt" content={"AnvioPark image"} />
    <link rel='canonical' href={pageUrl} />
  </Helmet>
);

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

export { MetaDecorator };