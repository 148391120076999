import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ExperienceStack } from '../../components/ExperienceStack';
import Fade from "react-reveal/Fade";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import {MetaDecorator} from "../../components";

import czImg1 from "../../assets/img/experiences/cityz/cityz-zombie.webp";
import cz1 from "../../assets/img/experiences/cityz/cz1.webp";
import cz3 from "../../assets/img/experiences/cityz/cz3.webp";
import cz4 from "../../assets/img/experiences/cityz/cz4.jpg";
import mainBg from "../../assets/img/experiences/cityz/czBackParallax.webp"
import secondBg from "../../assets/img/experiences/cityz/cz_sec1.mp4"
import genre_dec from "../../assets/img/decoration/genre.png"
import people_dec from "../../assets/img/decoration/people.png"
import time_dec from "../../assets/img/decoration/time.png"
import z1 from "../../assets/img/experiences/cityz/z1.webp";
import z2 from "../../assets/img/experiences/cityz/z2.webp";
import z3 from "../../assets/img/experiences/cityz/z3.webp";
import z4 from "../../assets/img/experiences/cityz/z4.webp";

export function CityZ({ city }) {
  const [isTrailerOpened, setIsTrailerOpened] = useState(false);
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  
  return (
    <div className="experiences">
      <MetaDecorator
          description=
              {
              `CityZ - the most popular VR experience!`
              }
          title={`Anvio VR Park - CityZ | ${city.toUpperCase()}`}
          imageUrl={mainBg}
          imageAlt={"Anvio Park CityZ"}
          pageUrl={`https://anviopark.com/${city}/experiences/CITYZ`}
      />
      <div className="main_background">
        <img src={mainBg} alt="" style={{width: screenWidth}}/>
        <MouseParallaxContainer
        className="paralax_background"
        useWindowMouseEvents
      >
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.006}
          factorY={0.009}
        >
          <img src={z1} alt="a" />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.01}
          factorY={0.02}
        >
          <img src={z2} alt="d" />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.02}
          factorY={0.03}
        >
          <img src={z3} alt="c" />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="cz_paralax"
          factorX={0.07}
          factorY={0.08}
        >
          <img src={z4} alt="b" />
        </MouseParallaxChild>
      </MouseParallaxContainer>
      </div>
      <div style={{position: "absolute", width: "100%", height: "100%"}}>
        <Fade duration={500} when={isTrailerOpened} collapse >
          <div className="trailer" onClick={() => setIsTrailerOpened(false)}>
            <iframe 
              style={{ width: "80%", height: "auto", aspectRatio: "16/9" }}
              width="1280" height="720" 
              src="https://www.youtube.com/embed/vNPfcHfEQa0?autoplay=0&controls=1&modestbranding=0&playsinline=1"
              showinfo="0" 
              title="YouTube video player" frameBorder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope;" 
              allowFullScreen></iframe>
          </div>
        </Fade>
      </div>
      <div className="main">
        <div className="container">
          <div className="main__inner">
            <Fade left cascade duration={500} distance="50px">
              <div className="left">
                <div className="main-title">City Z</div>
                <div className="description"><img src={genre_dec} alt="" />Genre: Horror/Shooter</div>
                <div className="description"><img src={people_dec} alt="" />People: 1-6 people</div>
                <div className="description"><img src={time_dec} alt="" />Session time: 30 mins</div>
                <div className="actions">
                  <Link to={`/${city}/book`} className="btn btn-primary">
                    Book an adventure
                  </Link>
                  <div className="btn btn-secondary" onClick={() => setIsTrailerOpened(true)}>
                    Watch trailer
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="experience" style={{height: screenHeight}}>
        <div className="secondary_background">
          <video className='main-video' loop muted autoPlay playsInline preload="auto" style={{width: screenWidth}}>
            <source src={secondBg} type="video/mp4" />
          </video>
        </div>
        <div className="container">
            <div className="experience__inner">
              <Fade left distance="50px">
                <div className="title">
                  «WE HAVE TO COME FOR THEM BEFORE THEY COME FOR US!»
                </div>
              </Fade>
              <div className="description">
                <Fade top >
                  <p>The most popular part of a zombie trilogy called CITY Z.</p>
                  <p>
                    The fate of mankind depends on YOUR actions. Zombie apocalypse
                    iS HERE!
                  </p>
                  <p>And We need you!</p>
                  <p>
                    You are a SWAT team that has to reach a secret lab in the ruined
                    City Z.
                  </p>
                  <p>
                    Learn to navigate in the dark and shoot hundreds of walking
                    dead. You have no limits other than your own fears.
                  </p>
                </Fade>
              </div>
            </div>
        </div>
      </div>
      
      <div className="intro">
        <div className="container">
          <div className="intro__inner">
            <Fade left cascade>
              <div className="intro__left" style={{left: -50}}>
                <img
                  src={czImg1}
                  className="img-responsive"
                  alt=""
                />
              </div>
            </Fade>
            <Fade right cascade>
              <div className="grid">
                <img
                  src={cz1}
                  className="grid__element"
                  alt=""
                />
                <img
                  src={cz3}
                  className="grid__element"
                  alt=""
                />
                <img
                  src={cz4}
                  className="grid__element"
                  alt=""
                />
                
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="booking">
        <div className="container">
          <Fade top cascade>
            <div className="booking__inner">
              <div className="modal__title">It's time to save the living</div>
              <div>
                <Link to={`/${city}/book`} className="btn btn-primary">
                  Book an adventure
                </Link>
              </div>
            </div>
          </Fade>
        </div>
        <div className="booking__cover"></div>
      </div>
      <ExperienceStack stackTitle={"Trending VR adventures"} city={city}/>
    </div>
  );
}
