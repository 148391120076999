import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Fade from "react-reveal/Fade";
import ApiService from "../services";

import LogoIcon from "../assets/svg/logo_icon.svg";
import LogoText from "../assets/svg/logo_txt.svg";
import battleGroundLogo from "../assets/img/battleground-los-angeles.webp";
import battleGroundSFLogo from "../assets/img/battleground-san-francisco.webp";
import menuBurger from "../assets/img/menu.png";
import phone_dec from "../assets/img/decoration/phone.png"

const cityInfo = {
    'san-diego': "+1 (858) 910-3820",
    'hollywood': "+1 (310) 803-9717",
    'battleground-la': "+1 (424) 999-8272",
    'battleground-sf': "+1 (949) 795-2010",
};

export function Header({ city, setCity }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [games, setGames] = useState([]);
  const [isExperienceModalOpen, setIsExperienceModalOpen] = useState(false);
  const { pathname } = useLocation();
  const [ mounted, setMounted ] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [city]);

  useEffect(() => {
    setIsBurgerOpen(false);
  }, [pathname]);

  useEffect(() => {
    setMounted(true);
    ApiService.getGames()
      .then(response => response.data)
      .then(data => {
        setGames(data)
      })
  }, [])

  const SetCity = (city) => {
    localStorage.setItem("localCity", city);
    navigate(city);
    setCity(city);
  };

  return (
    <>
      <div className="header" style={{
          height: isBurgerOpen ? "100%" : "100px",
          background: isBurgerOpen ? "linear-gradient(180deg, #000000 19.79%, rgba(0, 0, 0, 1) 100%)"
            : "linear-gradient(180deg, #000000 19.79%, rgba(0, 0, 0, 0) 100%)",
        }}>

        <div className="container">
          <div className="header__inner">
            <div className="nav">
              <div className="logo_menu">
                {
                  mounted ? <>{ city !== "battleground-la" && city !== "battleground-sf"
                  ? <Link to={`/${city}`} className="header__logo">
                      <img src={LogoIcon} alt="logo icon" className="logo_icon" />
                      <img src={LogoText} alt="logo text" className="logo_text" />
                    </Link>
                  : <Link to={`/${city}`} >
                      <img src={city === "battleground-la" ? battleGroundLogo : battleGroundSFLogo} className="battleground_logo" alt="battleground logo" />
                    </Link>
                    } </> : null
                }
                <img src={menuBurger} className="menu_icon" onClick={() => setIsBurgerOpen(!isBurgerOpen)} />
              </div>
              <nav>
                <Link to="#" className="nav__link active">
                  <div className="nav__link" onClick={() => setIsExperienceModalOpen(!isExperienceModalOpen)}>
                    Experiences
                  </div>
                </Link>
                <Link to={`/${city}/certificates`} className="nav__link">
                  Certificates
                </Link>
                <div className="nav__link" onClick={() => setIsOpen(!isOpen)}>
                  Locations
                </div>
                <Link to={`/${city}/event`} className="nav__link">
                  <b>PARTY</b>
                </Link>
                {(city !== 'hollywood') ? <Link to={`/${city}/club`} className="nav__link">
                  Cyber Club
                </Link> : null}
                <Link to={`/${city}/FAQ`} className="nav__link">
                  FAQ
                </Link>
                {/* {city === "battleground" ? 
                  <Link to={`/${city}/halloween`} className="nav__link" style={{color: "#F24726", fontWeight: 600}}>
                    Halloween Party
                  </Link> : null
                } */}
                <a href={`tel:${cityInfo[city]}`} id="header_btn" className="nav__link" 
                  style={{
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                    margin: "0 10px 0 10px"
                  }}>
                  <img src={phone_dec} alt="" style={{height: 16}}/>{cityInfo[city]} 
                </a>
              </nav>
            </div>
            <Link to={`/${city}/book`} id="header_btn" className="nav__link">
              <button className="nav__link">Book an adventure</button>
            </Link>
          </div>
        </div>
      </div>
      
      <div style={{ position: "absolute", width: "100%", height: isExperienceModalOpen || isOpen ? "100%" : "fit-content" }}>
        <Fade duration={500} when={isExperienceModalOpen} collapse >
          <div className="modal" onClick={() => setIsExperienceModalOpen(!isExperienceModalOpen)}>
            <div className="modal__content">
              <div className="modal__title">Experiences</div>
              <div className="experience_container">
                <Fade right cascade when={isExperienceModalOpen}>
                {
                  city !== "hollywood" && city !== "battleground-sf" ?
                  <div className="cards">
                      {
                        games.length > 0 && games.map((game, index) => <Link to={`/${city}/experiences/${(game.name).replace( /\s/g, '')}`} className="card" key={index} style={{ 
                            backgroundImage: `url("${game.image_512}")` 
                        }} >
                        <div className="card__title">
                          {game.name}
                        </div>
                      </Link>)
                      }
                    </div> :

                  <div className="cards">
                      {
                        games.length > 0 && games.map((game, index) => game.name !== "Versus" ? <Link to={`/${city}/experiences/${(game.name).replace( /\s/g, '')}`} className="card" key={index} style={{ 
                            backgroundImage: `url("${game.image_512}")` 
                        }}>
                        <div className="card__title">
                          {game.name}
                        </div>
                      </Link> : null)
                      }
                  </div>
                }
                </Fade>
              </div>
            </div>
          </div>
        </Fade>

        <Fade duration={500} when={isOpen} collapse>
          <div className="modal header" onClick={() => setIsOpen(!isOpen)}>
            <div className="modal__content">
              <div className="modal__title">SELECT YOUR LOCATION</div>
              <div className="modal__body" onClick={(e) => e.stopPropagation()}>
                <div className="continent">
                  <div className="continent__body">
                    <div className="country">
                      <div className="country__title">United States</div>
                      <div className="country__body">
                        <ul>
                          <li onClick={() => SetCity("san-diego")}>
                            San Diego
                          </li>
                          <li onClick={() => SetCity("hollywood")}>
                            Hollywood
                          </li>
                          <li onClick={() => SetCity("battleground-la")}>
                            Los Angeles - Battleground
                          </li>
                          <li onClick={() => SetCity("battleground-sf")}>
                            San Francisco - Battleground
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}
